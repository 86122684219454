<template>
  <NoticeList :category="11" :type="2" detailPath="warningInfoDetail" />
</template>
<script>
import NoticeList from '@/components/NoticeList.vue'

export default {
  name: 'AlertIssued',
  components: {
    NoticeList,
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style></style>
